var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "item-detail-page" } },
    [
      _c(
        "vs-alert",
        {
          attrs: {
            color: "danger",
            title: "Error Fetching Product Data",
            active: _vm.error_occured,
          },
          on: {
            "update:active": function ($event) {
              _vm.error_occured = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.error_msg) + ". ")]),
          _c(
            "span",
            [
              _c("span", [_vm._v("Check ")]),
              _c(
                "router-link",
                {
                  staticClass: "text-inherit underline",
                  attrs: { to: { name: "ecommerce-shop" } },
                },
                [_vm._v("All Items")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.item_data
        ? _c(
            "vx-card",
            { key: _vm.item_data.objectID },
            [
              _c("template", { slot: "no-body" }, [
                _c("div", { staticClass: "item-content" }, [
                  _c("div", { staticClass: "product-details p-6" }, [
                    _c("div", { staticClass: "vx-row mt-6" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col md:w-2/5 w-full flex items-center justify-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "product-img-container w-3/5 mx-auto mb-10 md:mb-0",
                            },
                            [
                              _c("img", {
                                staticClass: "responsive",
                                attrs: {
                                  src: "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/01.png",
                                  alt: _vm.item_data.name,
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-3/5 w-full" },
                        [
                          _c("h3", [_vm._v(_vm._s(_vm.item_data.name))]),
                          _c("p", { staticClass: "my-2" }, [
                            _c("span", { staticClass: "mr-2" }, [_vm._v("by")]),
                            _c("span", [_vm._v(_vm._s(_vm.item_data.brand))]),
                          ]),
                          _c(
                            "p",
                            { staticClass: "flex items-center flex-wrap" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-2xl leading-none font-medium text-primary mr-4 mt-2",
                                },
                                [_vm._v("$" + _vm._s(_vm.item_data.price))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "pl-4 mr-2 mt-2 border border-solid d-theme-border-grey-light border-t-0 border-b-0 border-r-0",
                                },
                                [
                                  _c("star-rating", {
                                    attrs: {
                                      "show-rating": false,
                                      rating: _vm.item_data.rating,
                                      "star-size": 20,
                                      "read-only": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "cursor-pointer leading-none mt-2",
                                },
                                [_vm._v("424 ratings")]
                              ),
                            ]
                          ),
                          _c("vs-divider"),
                          _c("p", [_vm._v(_vm._s(_vm.item_data.description))]),
                          _c(
                            "vs-list",
                            {
                              staticClass: "product-sales-meta-list px-0 pt-4",
                            },
                            [
                              _vm.item_data.free_shipping
                                ? _c("vs-list-item", {
                                    staticClass: "p-0 border-none",
                                    attrs: {
                                      title: "Free Sheeping",
                                      "icon-pack": "feather",
                                      icon: "icon-truck",
                                    },
                                  })
                                : _vm._e(),
                              _c("vs-list-item", {
                                staticClass: "p-0 border-none",
                                attrs: {
                                  title: "EMI options available",
                                  "icon-pack": "feather",
                                  icon: "icon-dollar-sign",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("vs-divider"),
                          _c("div", { staticClass: "vx-row" }, [
                            _c("div", { staticClass: "vx-col w-full" }, [
                              _c(
                                "span",
                                { staticClass: "text-xl font-medium" },
                                [_vm._v("Color")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap items-center mt-2",
                                },
                                _vm._l(
                                  _vm.available_item_colors,
                                  function (color) {
                                    return _c(
                                      "div",
                                      {
                                        key: color,
                                        staticClass:
                                          "color-radio rounded-full mx-1 border-2 border-solid cursor-pointer relative",
                                        class: {
                                          "border-transparent":
                                            _vm.opt_color != color,
                                        },
                                        style: _vm.itemColor({
                                          color: color,
                                          style: ["borderColor"],
                                        }),
                                        on: {
                                          click: function ($event) {
                                            _vm.opt_color = color
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "h-6 w-6 rounded-full absolute",
                                          style: _vm.itemColor({
                                            color: color,
                                            style: ["backgroundColor"],
                                          }),
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _c("vs-divider"),
                          _c("div", { staticClass: "vx-row" }, [
                            _c("div", { staticClass: "vx-col w-full" }, [
                              _c("p", { staticClass: "my-2" }, [
                                _c("span", [_vm._v("Available")]),
                                _c("span", { staticClass: "mx-2" }, [
                                  _vm._v("-"),
                                ]),
                                _c("span", { staticClass: "text-success" }, [
                                  _vm._v("In Stock"),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col w-full" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap items-start mb-4",
                                },
                                [
                                  !_vm.isInCart(_vm.item_data.objectID)
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mr-4 mb-4",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-shopping-cart",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleItemInCart(
                                                _vm.item_data
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      ADD TO CART\n                    "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "vs-button",
                                        {
                                          staticClass: "mr-4 mb-4",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-shopping-cart",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.$router
                                                .push({
                                                  name: "ecommerce-checkout",
                                                })
                                                .catch((err) => {})
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      VIEW IN CART\n                    "
                                          ),
                                        ]
                                      ),
                                  _vm.isInWishList(_vm.item_data.objectID)
                                    ? _c(
                                        "vs-button",
                                        {
                                          key: "filled",
                                          staticClass: "mb-4",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-heart",
                                            color: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleItemInWishList(
                                                _vm.item_data
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      WHISHLIST\n                    "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "vs-button",
                                        {
                                          key: "border",
                                          staticClass: "mb-4",
                                          attrs: {
                                            type: "border",
                                            "icon-pack": "feather",
                                            icon: "icon-heart",
                                            color: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleItemInWishList(
                                                _vm.item_data
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      WHISHLIST\n                    "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col flex flex-wrap items-center",
                              },
                              [
                                _c("vs-button", {
                                  staticClass: "mr-4",
                                  attrs: {
                                    type: "border",
                                    "icon-pack": "feather",
                                    color: "#1551b1",
                                    icon: "icon-facebook",
                                    radius: "",
                                  },
                                }),
                                _c("vs-button", {
                                  staticClass: "mr-4",
                                  attrs: {
                                    type: "border",
                                    "icon-pack": "feather",
                                    color: "#00aaff",
                                    icon: "icon-twitter",
                                    radius: "",
                                  },
                                }),
                                _c("vs-button", {
                                  staticClass: "mr-4",
                                  attrs: {
                                    type: "border",
                                    "icon-pack": "feather",
                                    color: "#c4302b",
                                    icon: "icon-youtube",
                                    radius: "",
                                  },
                                }),
                                _c("vs-button", {
                                  staticClass: "mr-4",
                                  attrs: {
                                    type: "border",
                                    "icon-pack": "feather",
                                    color: "#405DE6",
                                    icon: "icon-instagram",
                                    radius: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "py-24 mb-16 mt-10 text-center item-features",
                    },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                          _c(
                            "div",
                            { staticClass: "w-64 mx-auto mb-16 md:mb-0" },
                            [
                              _c("feather-icon", {
                                staticClass: "block mb-4",
                                attrs: {
                                  icon: "AwardIcon",
                                  svgClasses:
                                    "h-12 w-12 text-primary stroke-current",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "font-semibold text-lg" },
                                [_vm._v("100% Original")]
                              ),
                              _c("p", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "Chocolate bar candy canes ice cream toffee cookie halvah."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                          _c(
                            "div",
                            { staticClass: "w-64 mx-auto mb-16 md:mb-0" },
                            [
                              _c("feather-icon", {
                                staticClass: "block mb-4",
                                attrs: {
                                  icon: "ClockIcon",
                                  svgClasses:
                                    "h-12 w-12 text-primary stroke-current",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "font-semibold text-lg" },
                                [_vm._v("10 Day Replacement")]
                              ),
                              _c("p", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "Marshmallow biscuit donut dragée fruitcake wafer."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                          _c(
                            "div",
                            { staticClass: "w-64 mx-auto" },
                            [
                              _c("feather-icon", {
                                staticClass: "block mb-4",
                                attrs: {
                                  icon: "ShieldIcon",
                                  svgClasses:
                                    "h-12 w-12 text-primary stroke-current",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "font-semibold text-lg" },
                                [_vm._v("1 Year Warranty")]
                              ),
                              _c("p", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "Cotton candy gingerbread cake I love sugar sweet."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "related-products text-center px-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "related-headings mb-8 text-center" },
                      [
                        _c("h2", { staticClass: "uppercase" }, [
                          _vm._v("Related Products"),
                        ]),
                        _c("p", [_vm._v("People also search for this items")]),
                      ]
                    ),
                    _c(
                      "swiper",
                      {
                        key: _vm.$vs.rtl,
                        staticClass: "related-product-swiper px-12 py-6",
                        attrs: {
                          options: _vm.swiperOption,
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                      },
                      [
                        _vm._l(_vm.related_items, function (item) {
                          return _c(
                            "swiper-slide",
                            {
                              key: item.objectId,
                              staticClass: "p-6 rounded cursor-pointer",
                            },
                            [
                              _c("div", { staticClass: "item-heading mb-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-lg font-semibold truncate",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c("p", { staticClass: "text-sm" }, [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v("by"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.brand))]),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "img-container w-32 mx-auto my-base",
                                },
                                [
                                  _c("img", {
                                    staticClass: "responsive",
                                    attrs: { src: item.image, alt: item.name },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "item-meta" },
                                [
                                  _c("star-rating", {
                                    staticClass: "justify-center",
                                    attrs: {
                                      "show-rating": false,
                                      rating: item.rating,
                                      "star-size": 14,
                                      "read-only": "",
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-lg font-medium text-primary",
                                    },
                                    [_vm._v("$" + _vm._s(item.price))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _c("div", {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev",
                        }),
                        _c("div", {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next",
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }